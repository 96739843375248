const state = {
  search: {
    data: null,
    query: '',
    filter: {},
    filterInfo: [],
    sort: 'published',
    published: [],
    results: [],
    pageSize: 20,
    pageNum: 1,
    filterByCompleted: false,
    filterByStarted: false,
    filterByCollaboration: false,
    displayFilterByCollaboration: false,
    route: '',
  },
};

// Metoder for å oppdatere informasjon i store
const mutations = {
  // Push filter ID to store (if not already exists)
  setFilter(state, payload) {
    if (payload.filterKey !== undefined) {
      let key = `filter${payload.filterKey}`;
      if (payload.filterKey === null) {
        key = 'filterSubFilter';
      }
      const found = state.search.filterInfo.some((item) => item.filterId === payload.filterId);
      if ((state.search.filterInfo === null || !found)) {
        state.search.filterInfo.push(payload);
      }
      let arr = [];
      // If key exists, append ID to array
      if (key in state.search.filter) {
        // Get existing array with ids
        arr = state.search.filter[key];
        // Check if ID already exsist in aray, else add
        if (!arr.includes(payload.filterId)) {
          arr.push(payload.filterId);
        }

        state.search.filter[key] = arr;
      } else if (key === 'filterSubFilter') {
        state.search.subFilter = { filterSubFilter: payload.filterId };
        if (payload.filterGroup === 'Municipality') {
          state.search.filter = { filterSubFilter: [payload.filterId], filterMunicipality: [], filterAssessmentType: state.search.filter.filterAssessmentType };
        } else if (payload.filterGroup !== undefined && state.search.filter.filterAssessmentType) {
          state.search.filter = { filterSubFilter: [payload.filterId], filterTopic: [], filterAssessmentType: state.search.filter.filterAssessmentType };
        } else if (payload.filterGroup !== undefined) {
          state.search.filter = { filterSubFilter: [payload.filterId], filterTopic: [] };
        }
      } else {
        // Add new key and value
        state.search.filter[key] = [payload.filterId];
      }
    }
  },
  // Remove filter ID from store (if exists)
  unsetFilter(state, payload) {
    const key = `filter${payload.filterKey}`;
    const index = state.search.filter[key].map((item) => item).indexOf(payload.filterId);
    const { filterInfo } = state.search;
    const filteredFilterInfo = filterInfo.filter((item) => item.filterId !== payload.filterId);
    state.search.filter[key].splice(index, 1);
    state.search.filterInfo = filteredFilterInfo;
  },
  // Remove filter ID with key from store (if exists)
  unsetFilterAll(state, payload) {
    const key = `filter${payload.filterKey}`;
    state.search.filter = {};
    state.search.filterInfo = [];
    state.search.filter[key] = [];
  },

  // Set query string
  setQuery(state, payload) {
    state.search.query = payload;
  },

  // Set sort
  setSort(state, payload) {
    state.search.sort = payload;
  },

  // Set search result
  setResults(state, payload) {
    state.search.result = payload;
  },

  // Set page
  setPageNum(state, payload) {
    state.search.pageNum = parseInt(payload, 10);
  },

  // Set default assessmenttype
  setDefaultAssessmentType() {
    const assessmentFilterArray = [];
    assessmentFilterArray.push(1);
    state.search.filter.filterAssessmentType = assessmentFilterArray;
  },

  // Set filterByCompleted
  setFilterByCompleted(state, payload) {
    state.search.filterByCompleted = payload;
  },

  // Set displayFilterByCollaboration
  setDisplayFilterByCompleted(state, payload) {
    state.search.displayFilterByCompleted = payload;
  },

  // Set filterByCollaboration
  setFilterByCollaboration(state, payload) {
    state.search.filterByCollaboration = payload;
  },

  // Set filterByStarted
  setFilterByStarted(state, payload) {
    state.search.filterByStarted = payload;
  },
  // Set published
  setPublished(state, payload) {
    state.search.published = payload;
  },
  // Clear all filters
  clearFilters(state) {
    state.search.filter = {};
    state.search.filterInfo = [];
  },
  clearSearch(state) {
    state.search.filter = {};
    state.search.filterInfo = [];
    state.search.published = [];
    state.search.query = '';
    state.search.sort = '';
  },
  clearAll(state) {
    state.search.filter = {};
    state.search.filterInfo = [];
    state.search.published = [];
    state.search.query = '';
    state.search.sort = '';
    global.router.push({ path: window.location.pathname }).then(() => window.location.reload());
  },
};

const actions = {
  // Alltid putt async handlinger innenfor actions
  async performSearch(state) {
    const applicationPath = window.location.origin;
    // const applicationPath = 'http://fhi:8085';
    const query = {
      q: state.state.search.query,
      page: state.state.search.pageNum,
      pageSize: state.state.search.pageSize,
      sort: state.state.search.sort,
      published: state.state.search.published,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(state.state.search.filter)) {
      query[key] = value.join(',');
    }
    global.router.push({ path: window.location.pathname, query });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        q: state.state.search.query,
        page: state.state.search.pageNum,
        pageSize: state.state.search.pageSize,
        sort: state.state.search.sort,
        published: state.state.search.published,
        filter: state.state.search.filter,
        subFilter: state.state.search.filter.subFilters,
        filterByCompleted: state.state.search.filterByCompleted,
        filterByStarted: state.state.search.filterByStarted,
        filterByCollaboration: state.state.search.filterByCollaboration,
        displayFilterByCollaboration: state.state.search.displayFilterByCollaboration,
      }),
    };
    const response = await fetch(`${applicationPath}/api/search/${state.state.search.route}`, requestOptions);
    return response.json().then((el) => {
      state.state.search.data = el;
      state.state.search.results = el.searchResult;
    });
  },

  setSearchRoute(state, payload) {
    state.state.search.route = payload;
  },
};

const modules = {
};

const getters = {
  // Hent informasjon som kan brukes i APP-en
  getData: (state) => state.search.data,
  getFilter: (state) => state.search.filter,
  getFilterByCompleted: (state) => state.search.filterByCompleted,
  getFilterByStarted: (state) => state.search.filterByStarted,
  getFilterByCollaboration: (state) => state.search.filterByCollaboration,
  getDisplayFilterByCollaboration: (state) => state.search.displayFilterByCollaboration,
  getFilterInfo: (state) => state.search.filterInfo,
  getPageNum: (state) => state.search.pageNum,
  getPageSize: (state) => state.search.pageSize,
  getSearch: (state) => state.search,
  getPublished: (state) => state.search.published,
  getSearchResults: (state) => state.search.results,
  getSearchQuery: (state) => state.search.query,
  getSort: (state) => state.search.sort,
};

export default {
  state,
  mutations,
  actions,
  modules,
  getters,
};
