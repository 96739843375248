<template>
  <div class="container mb-8">
    <div class="block lg:flex">
      <aside class="w-full pr-0 mb-4 lg:w-1/4 lg:pl-4">
      </aside>
      <div class="w-full pr-0 lg:w-2/4 lg:pr-4">
        <div v-if="inEditMode">
          <EpiProperty propertyName="DefinitionReference" buttonText="Hvilken skjemamal skal brukes?" />
          <EpiProperty propertyName="SchemasDestination" buttonText="Hvor skal skjema opprettes?" />
        </div>
        <div class="my-2" v-if="model.heading || inEditMode" v-epi-edit="'Heading'">
          <h1 class="font-serif font-semibold text-hmd">{{model.heading}}</h1>
        </div>
        <div class="my-2" v-if="model.subHeading || inEditMode" v-epi-edit="'SubHeading'">
          <p class="text-lg">{{model.subHeading}}</p>
        </div>
        <div class="my-8 rounded bg-bluegray-300 article-small" v-if="model.mainBody || inEditMode" v-epi-edit="'MainBody'">
          <div v-if="model.mainBody" v-html="model.mainBody" class="p-4"></div>
        </div>
        <div class="my-2" v-if="model.formHeading || inEditMode || !isSubmitted" v-epi-edit="'FormHeading'">
          <p class="text-lg">{{model.formHeading}}</p>
        </div>
        <form @submit.prevent="create">
          <div class="mt-2 mb-2">
            <label for="title" class="text-sm">{{$t('schema.introPage.methodEvaluationTitle.label')}}:</label>
            <input v-if="!isSubmitted"
                   id="title"
                   type="text"
                   v-model="title"
                   :placeholder="assessmentType === '1' ? $t('schema.introPage.methodEvaluationTitle.placeholder') : ''"
                   required />
            <input v-else
                   type="text"
                   v-model="title"
                   disabled />
          </div>

          <!-- Spesialisthelsetjenesten -->
          <div class="mt-2 mb-2" v-if="assessmentType === '1'">
            <label for="organization" class="text-sm">{{$t('schema.introPage.organization.label')}}</label>
            <select v-if="!isSubmitted"
                    class="p-4 bg-transparent border-2"
                    v-model="organization"
                    required>
              <option value="" disabled>{{$t('schema.introPage.organization.optionDescription')}}</option>
              <option v-for="(option, index) in organizations" :key="$uuid(index)" :value="option.value">{{option.text}}</option>
            </select>
            <select v-else
                    class="p-4 bg-transparent border-2"
                    v-model="organization"
                    disabled>
              <option v-for="(option, index) in organizations" :key="$uuid(index)" :value="option.value">{{option.text}}</option>
            </select>
          </div>

          <!-- Kommunehelsetjenesten og samhandling -->
          <div class="mt-2 mb-2" v-if="assessmentType === '2' || assessmentType === '3'">
            <label class="text-sm">Fylke</label>
            <select v-model="selectedCounty">
              <option v-for="(county, oindex) in organizations"
                      :key="$uuid(oindex)" :label="county.text"
                      :value="county.value">{{county.text}}</option>
            </select>
            <div v-if="selectedCounty !== null">
              <label class="text-sm">Kommune</label>
              <select v-model="organization">
                <option v-for="(municipality, oindex) in getMunicipalities(selectedCounty)"
                        :key="$uuid(oindex)"
                        :label="municipality.text"
                        :value="municipality.value">{{municipality.text}}</option>
              </select>
            </div>
          </div>

          <div class="mt-2 mb-2">
            <label for="name" class="text-sm">{{$t('schema.introPage.name.label')}}:</label>
            <input v-if="!isSubmitted"
                   id="name"
                   type="text"
                   v-model="name"
                   :placeholder="$t('schema.introPage.name.placeholder')"
                   required />
            <input v-else type="text"
                   v-model="name"
                   disabled />
          </div>
          <div class="mt-2 mb-2">
            <label for="email" class="text-sm">{{$t('schema.introPage.email.label')}}:</label>
            <input v-if="!isSubmitted"
                   id="email"
                   type="email"
                   v-model="email"
                   :placeholder="$t('schema.introPage.email.placeholder')"
                   @input="validateEmail"
                   required />
            <input v-else type="text"
                   v-model="email"
                   disabled />
            <span v-if="emailError && !isSubmitted" style="color: red;">{{ emailError }}</span>
          </div>
          <div class="my-4 article-small"
               v-show="!isSubmitted"
               v-html="model.secondaryBody"
               v-if="model.secondaryBody || inEditMode"
               v-epi-edit="'SecondaryBody'" />
          <div class="bg-green-100 rounded py-4 px-4 opacity-80 article-small"
               v-show="isSubmitted || inEditMode"
               v-html="model.sentInMessage"
               v-if="model.sentInMessage || inEditMode"
               v-epi-edit="'SentInMessage'" />
          <div v-show="!isSubmitted" class="flex justify-end">
            <button type="submit" class="btn btn--large btn--charcoalgray" :disabled="emailError">
              <font-awesome-icon icon="arrow-right" />
              {{$t('schema.introPage.continue.text')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import EpiProperty from '@/components/EpiProperty.vue';

  export default {
    props: ['model'],
    components: {
      EpiProperty,
    },
    computed: mapState({
      inEditMode: (state) => state.epiContext.inEditMode,
    }),
    data() {
      return {
        none: '',
        title: '',
        email: '',
        emailError: null,
        name: '',
        organization: this.$t('schema.introPage.organization.optionDescription'),
        organizations: this.model.organizations,
        assessmentType: this.model.assessmentType,
        id: this.model.contentLink.id,
        isSubmitted: false,
        selectedCounty: null,
        selectedMunicipality: null,
      };
    },
    mounted() {
      this.setMetaTags();
    },
    methods: {
      validateEmail() {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (this.email === '') {
          this.emailError = 'E-postaddresse er påkrevd';
        } else if (!emailPattern.test(this.email)) {
          this.emailError = 'Du må skrive inn en gyldig e-postaddresse';
        } else {
          this.emailError = null;
        }
      },
      setMetaTags() {
        document.title = this.model.name;
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
      },
      getMunicipalities(county) {
        const result = this.organizations.filter((item) => item.value === county);
        if (result !== null) {
          return result[0].subItems;
        }
        return [];
      },
      create() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.id,
            name: this.name,
            title: this.title,
            email: this.email,
            organizationId: this.organization,
            assessmentType: this.assessmentType,
          }),
        };
        this.isSubmitted = true;
        fetch(`${window.location.origin}/api/schema/CreateMethodAssessment`, requestOptions)
          .then((response) => response.json())
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
</script>
