<template>
  <div class="w-full xl:w-3/4 pr-0">
    <div v-if="model.title">
      <FieldTitle :model="model" />
    </div>
  </div>
  <div class="w-full mb-10">
    <!--Draft mode-->
    <div v-if="!readOnly">

      <!--Info text-->
      <div class="mb-6 mt-6" v-if="model.properties.infoTextForDraft" v-html="model.properties.infoTextForDraft">
      </div>

      <!--File upload-->
      <div v-if="((!uploadFinished || !uploadResult) && !fileName) || removeFileResult " class="mb-2">
        <button class="btn btn--charcoalgray" @click="onFileUploadClick">
          <font-awesome-icon icon="file" class="mr-2" />
          <span>{{$t('schema.common.action.selectFile')}}</span>
        </button>
        <input type="file"
               style="display: none"
               ref="fileInput"
               accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
               @change="fileUploadChange" />

        <!--Small help text-->
        <div class="mt-4">
          <span class="text-gray-200">{{$t('schema.common.action.upload.feedback.supportedFormat', { formats : '.xlsx' })}}</span>
        </div>
      </div>

      <!--Uploaded file-->
      <div class="mb-12">
        <div v-if="fileName && !removeFileResult">
          <font-awesome-icon icon="file" class="mr-2" />
          <span class="mr-4">økonomiske-aspekter.xlsx</span>
          <button class="mr-2" @click="removeFile">
            <span class="btn--link">{{$t('schema.common.action.remove')}}</span>
          </button>
        </div>
        <div v-else-if="uploadResult & !removeFileResult">
          <font-awesome-icon icon="file" class="mr-2" />
          <span class="mr-4">økonomiske-aspekter.xlsx</span>
          <button class="mr-2" @click="removeFile">
            <span class="btn--link">{{$t('schema.common.action.remove')}}</span>
          </button>
        </div>
      </div>

      <!--Feedback-->
      <div class="mb-2">
        <div v-if="uploadFinished && !uploadResult">
          <span>{{$t('schema.common.action.upload.feedback.errorDuringUpload')}}</span>
          <span>{{this.statusMessage}}</span>
        </div>
      </div>

    </div>

    <!--Public mode-->
    <div v-else>
      <!--Info text-->
      <div v-if="this.excelFileUrl && model.properties.infoTextForReader" class="mb-6 mt-6" v-html="model.properties.infoTextForReader">
      </div>
      <!--Excel file for download-->
      <a v-if="this.excelFileUrl" :href="this.excelFileUrl" class="py-3 underline text-lightblue-300" title="$t('schema.common.action.download.description')">
        <font-awesome-icon icon="file" class="mr-2" />
        <span>{{$t('schema.common.action.download.text.foundationFile')}}</span>
      </a>
    </div>
  </div>

  <!--Oppstartskostnader-->
  <div v-if="model.properties.startupCosts">
    <Section :pageId="pageId" :preview="preview" :model="model.properties.startupCosts" @on-save="saveInput" :readOnly="readOnly">
      <!--Excel numbers-->
      <div v-if="this.numbers">
        <div class="mb-4" v-if="!readOnly">
          <font-awesome-icon class="mr-2" icon="info" /><span>{{$t('schema.common.action.upload.numbersFromExcel')}}</span>
        </div>
        <div>
          <table class="table-auto mb-12" :class="preview ? 'w-full' : 'w-3/4'">
            <thead>
              <tr class="bg-bluegray-300 p-4">
                <th class="border px-4 py-4">Likviditetsbehov ift. investeringer og engangskostnader</th>
                <th class="border px-4 py-4">År 1</th>
                <th class="border px-4 py-4">År 2</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border px-4 py-2">Investeringsbehov</td>
                <td class="border px-4 py-2">{{numbers.startup_b12}}</td>
                <td class="border px-4 py-2">{{numbers.startup_c12}}</td>
              </tr>
              <tr>
                <td class="border px-4 py-2">Andre engangskostnader</td>
                <td class="border px-4 py-2">{{numbers.startup_b13}}</td>
                <td class="border px-4 py-2">{{numbers.startup_c13}}</td>
              </tr>
              <tr>
                <td class="border px-4 py-2">Sum</td>
                <td class="border px-4 py-2">{{numbers.startup_b14}}</td>
                <td class="border px-4 py-2">{{numbers.startup_c14}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--Custom numbers-->
      <div v-else>
        <div class="mb-4" v-if="!readOnly">
          <font-awesome-icon class="mr-2" icon="info" /><span>{{$t('schema.common.action.upload.manualNumbers')}}</span>
        </div>
        <table class="mb-12 table__editable" :class="preview ? 'w-full' : 'w-3/4'">
          <thead>
            <tr class="bg-bluegray-300 p-4">
              <th class="border px-4 py-4">Likviditetsbehov ift. investeringer og engangskostnader</th>
              <th class="border px-4 py-4">År 1</th>
              <th class="border px-4 py-4">År 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Investeringsbehov</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{startup_1}}</span>
                <input v-else type="number" v-model="startup_1" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{startup_2}}</span>
                <input v-else type="number" v-model="startup_2" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Andre engangskostnader</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{startup_3}}</span>
                <input v-else type="number" v-model="startup_3" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{startup_4}}</span>
                <input v-else type="number" v-model="startup_4" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Sum</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{startup_5}}</span>
                <input v-else type="number" v-model="startup_5" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{startup_6}}</span>
                <input v-else type="number" v-model="startup_6" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-full mb-4" v-if="!readOnly">
          <button @click="saveCustomNumbers" class="btn btn--charcoalgray">Lagre</button>
        </div>
      </div>
    </Section>
  </div>

  <!-- Pasientgrunnlag-->
  <div v-if="model.properties.patientBase">
    <Section :pageId="pageId" :preview="preview" :model="model.properties.patientBase" @on-save="saveInput" :readOnly="readOnly">
    </Section>
  </div>

  <!-- Driftskostnader -->
  <div v-if="model.properties.operationalCosts">
    <Section :pageId="pageId" :preview="preview" :model="model.properties.operationalCosts" @on-save="saveInput" :readOnly="readOnly">
      <!--Operational costs each patient-->
      <!--Excel numbers-->
      <div v-if="this.numbers">
        <div class="mb-4" v-if="!readOnly">
          <font-awesome-icon class="mr-2" icon="info" /><span>{{$t('schema.common.action.upload.numbersFromExcel')}}</span>
        </div>
        <table class="table-auto mb-12" :class="preview ? 'w-full' : 'w-3/4'">
          <thead>
            <tr class="bg-bluegray-300 p-4">
              <th class="border px-4 py-4">Driftskostnader per pasient</th>
              <th class="border px-4 py-4"></th>
              <th class="border px-4 py-4">Gammel metode</th>
              <th class="border px-4 py-4"></th>
              <th class="border px-4 py-4">Ny metode</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Diagnostisering</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_c4}}</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_e4}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Behandling</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_c5}}</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_e5}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Oppfølging</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_c6}}</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_e6}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Sum kostnad per pasient</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_c7}}</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_e7}}</td>
            </tr>
            <tr>
              <td class="border td__empty" colspan="5" />
            </tr>
            <tr>
              <td class="border px-4 py-2">Endring i kostnader ved ny metode per pasient</td>
              <td class="border px-4 py-2" colspan="3"></td>
              <td class="border px-4 py-2">{{numbers.operational_e9}}</td>
            </tr>
          </tbody>
        </table>
        <!--Operational costs all patients-->
        <table class="table-auto mb-12" :class="preview ? 'w-full' : 'w-3/4'">
          <thead class="bg-bluegray-300 p-4">
            <tr>
              <th class="border px-4 py-4">Driftskostnader alle pasienter</th>
              <th class="border px-4 py-4">Antall pasienter</th>
              <th class="border px-4 py-4">Gammel metode</th>
              <th class="border px-4 py-4">Antall pasienter</th>
              <th class="border px-4 py-4">Ny metode</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Diagnostisering</td>
              <td class="border px-4 py-2">{{numbers.operational_b12}}</td>
              <td class="border px-4 py-2">{{numbers.operational_c12}}</td>
              <td class="border px-4 py-2">{{numbers.operational_d12}}</td>
              <td class="border px-4 py-2">{{numbers.operational_e12}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Behandling</td>
              <td class="border px-4 py-2">{{numbers.operational_b13}}</td>
              <td class="border px-4 py-2">{{numbers.operational_c13}}</td>
              <td class="border px-4 py-2">{{numbers.operational_d13}}</td>
              <td class="border px-4 py-2">{{numbers.operational_e13}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Oppfølging</td>
              <td class="border px-4 py-2">{{numbers.operational_b14}}</td>
              <td class="border px-4 py-2">{{numbers.operational_c14}}</td>
              <td class="border px-4 py-2">{{numbers.operational_d14}}</td>
              <td class="border px-4 py-2">{{numbers.operational_e14}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Kostnad for alle pasienter</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_c15}}</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-4 py-2">{{numbers.operational_e15}}</td>
            </tr>
            <tr>
              <td class="border td__empty" colspan="5" />
            </tr>
            <tr>
              <td class="border px-4 py-2">Endring i kostnader ved ny metode for alle pasienter</td>
              <td class="border px-4 py-2" colspan="3"></td>
              <td class="border px-4 py-2">{{numbers.operational_e17}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--Custom numbers-->
      <div v-else>
        <div class="mb-4" v-if="!readOnly">
          <font-awesome-icon class="mr-2" icon="info" /><span>{{$t('schema.common.action.upload.manualNumbers')}}</span>
        </div>
        <table class="table-fixed mb-12 table__editable" :class="preview ? 'w-full' : 'w-3/4'">
          <thead class="bg-bluegray-300 p-4">
            <tr>
              <th class="w-48 border px-4 py-4">Driftskostnader per pasient</th>
              <th class="w-4 border px-4 py-4"></th>
              <th class="border px-4 py-4">Gammel metode</th>
              <th class="w-4 border px-4 py-4"></th>
              <th class="border px-4 py-4">Ny metode</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Diagnostisering</td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_1}}</span>
                <input v-else type="number" v-model="operational_1" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_2}}</span>
                <input v-else type="number" v-model="operational_2" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Behandling</td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_3}}</span>
                <input v-else type="number" v-model="operational_3" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_4}}</span>
                <input v-else type="number" v-model="operational_4" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Oppfølging</td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_5}}</span>
                <input v-else type="number" v-model="operational_5" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_6}}</span>
                <input v-else type="number" v-model="operational_6" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Sum kostnad per pasient</td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_7}}</span>
                <input v-else type="number" v-model="operational_7" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_8}}</span>
                <input v-else type="number" v-model="operational_8" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border td__empty" colspan="5" />
            </tr>
            <tr>
              <td class="border px-4 py-2" colspan="4">Endring i kostnader ved ny metode per pasient</td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_9}}</span>
                <input v-else type="number" v-model="operational_9" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
          </tbody>
        </table>
        <!--Operational costs all patients-->
        <table class="table-fixed mb-12 table__editable" :class="preview ? 'w-full' : 'w-3/4'">
          <thead>
            <tr class="bg-bluegray-300 p-4">
              <th class="w-48 border px-4 py-4">Driftskostnader alle pasienter</th>
              <th class="border px-4 py-4">Antall pasienter</th>
              <th class="border px-4 py-4">Gammel metode</th>
              <th class="border px-4 py-4">Antall pasienter</th>
              <th class="border px-4 py-4">Ny metode</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Diagnostisering</td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_11}}</span>
                <input v-else type="number" v-model="operational_11" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_12}}</span>
                <input v-else type="number" v-model="operational_12" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_13}}</span>
                <input v-else type="number" v-model="operational_13" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_14}}</span>
                <input v-else type="number" v-model="operational_14" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Behandling</td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_15}}</span>
                <input v-else type="number" v-model="operational_15" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_16}}</span>
                <input v-else type="number" v-model="operational_16" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_17}}</span>
                <input v-else type="number" v-model="operational_17" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_18}}</span>
                <input v-else type="number" v-model="operational_18" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Oppfølging</td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_19}}</span>
                <input v-else type="number" v-model="operational_19" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_20}}</span>
                <input v-else type="number" v-model="operational_20" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_21}}</span>
                <input v-else type="number" v-model="operational_21" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_22}}</span>
                <input v-else type="number" v-model="operational_22" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Kostnad for alle pasienter</td>
              <td class="border px-4 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_23}}</span>
                <input v-else type="number" v-model="operational_23" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2"></td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_24}}</span>
                <input v-else type="number" v-model="operational_24" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
            <tr>
              <td class="border td__empty" colspan="5" />
            </tr>
            <tr>
              <td class="border px-4 py-2" colspan="4">Endring i kostnader ved ny metode for alle pasienter</td>
              <td class="border px-1 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{operational_25}}</span>
                <input v-else type="number" v-model="operational_25" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-full mb-4" v-if="!readOnly">
          <button @click="saveCustomNumbers" class="btn btn--charcoalgray">Lagre</button>
        </div>
      </div>
    </Section>
  </div>

  <!--Budget consequences-->
  <div v-if="model.properties.budget">
    <Section :pageId="pageId" :preview="preview" :model="model.properties.budget" @on-save="saveInput" :readOnly="readOnly">
      <!--Excel numbers-->
      <div v-if="this.numbers">
        <div class="mb-4" v-if="!readOnly">
          <font-awesome-icon class="mr-2" icon="info" /><span>{{$t('schema.common.action.upload.numbersFromExcel')}}</span>
        </div>
        <table class="table-fixed mb-12" :class="preview ? 'w-full' : 'w-3/4'">
          <thead>
            <tr class="bg-bluegray-300 p-4">
              <th class="border px-4 py-4">Budsjettmessige konsekvenser</th>
              <th class="border px-4 py-4">Inneværende budsjettår</th>
              <th class="border px-4 py-4">Neste budsjettår</th>
              <th class="border px-4 py-4">Kommentarer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Endring i inntekt som følge av ny metode</td>
              <td class="border px-4 py-2">{{numbers.budget_b17}}</td>
              <td class="border px-4 py-2">{{numbers.budget_c17}}</td>
              <td class="border px-4 py-2 break-words">{{comments.budget_comment17}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Likviditetsbehov ift. investeringer og engangskostnader</td>
              <td class="border px-4 py-2">{{numbers.budget_b18}}</td>
              <td class="border px-4 py-2">{{numbers.budget_c18}}</td>
              <td class="border px-4 py-2 break-words">{{comments.budget_comment18}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Årlig endring i kostnader med ny metode for alle pasienter</td>
              <td class="border px-4 py-2">{{numbers.budget_b19}}</td>
              <td class="border px-4 py-2">{{numbers.budget_c19}}</td>
              <td class="border px-4 py-2 break-words">{{comments.budget_comment19}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Evt. annet; inntekter (+) / utgifter (-)</td>
              <td class="border px-4 py-2">{{numbers.budget_b20}}</td>
              <td class="border px-4 py-2">{{numbers.budget_c20}}</td>
              <td class="border px-4 py-2 break-words">{{comments.budget_comment20}}</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Netto</td>
              <td class="border px-4 py-2">{{numbers.budget_b21}}</td>
              <td class="border px-4 py-2">{{numbers.budget_c21}}</td>
              <td class="border px-4 py-2 break-words">{{comments.budget_comment21}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--Custom numbers-->
      <div v-else>
        <div class="mb-4" v-if="!readOnly">
          <font-awesome-icon class="mr-2" icon="info" /><span>{{$t('schema.common.action.upload.manualNumbers')}}</span>
        </div>
        <table class="table-fixed mb-12 table__editable" :class="preview ? 'w-full' : 'w-3/4'">
          <thead>
            <tr class="bg-bluegray-300 p-4">
              <th class="border px-4 py-4">Budsjettmessige konsekvenser</th>
              <th class="border px-4 py-4">Inneværende budsjettår</th>
              <th class="border px-4 py-4">Neste budsjettår</th>
              <th class="border px-4 py-4">Kommentarer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">Endring i inntekt som følge av ny metode</td>
              <td class="border px-4 py-2 ">
                <span v-if="readOnly" class="px-4 py-2">{{budget_1}}</span>
                <input v-else type="number" v-model="budget_1" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_2}}</span>
                <input v-else type="number" v-model="budget_2" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2 break-words">
                <div v-if="readOnly" class="px-4 py-2">{{budget_3}}</div>
                <textarea v-else v-model="budget_3" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Likviditetsbehov ift. investeringer og engangskostnader</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_4}}</span>
                <input v-else type="number" v-model="budget_4" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_5}}</span>
                <input v-else type="number" v-model="budget_5" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2 break-words">
                <div v-if="readOnly" class="px-4 py-2">{{budget_6}}</div>
                <textarea v-else v-model="budget_6" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Årlig endring i kostnader med ny metode for alle pasienter</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_7}}</span>
                <input v-else type="number" v-model="budget_7" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_8}}</span>
                <input v-else type="number" v-model="budget_8" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2 break-words">
                <div v-if="readOnly" class="px-4 py-2">{{budget_9}}</div>
                <textarea v-else v-model="budget_9" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Evt. annet; inntekter (+) / utgifter (-)</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_10}}</span>
                <input v-else type="number" v-model="budget_10" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_11}}</span>
                <input v-else type="number" v-model="budget_11" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2 break-words">
                <div v-if="readOnly" class="px-4 py-2">{{budget_12}}</div>
                <textarea v-else v-model="budget_12" />
              </td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Netto</td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_13}}</span>
                <input v-else type="number" v-model="budget_13" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2">
                <span v-if="readOnly" class="px-4 py-2">{{budget_14}}</span>
                <input v-else type="number" v-model="budget_14" v-on:blur="setDefault" v-on:focus="removeZero" />
              </td>
              <td class="border px-4 py-2 break-words">
                <div v-if="readOnly" class="px-4 py-2">{{budget_15}}</div>
                <textarea v-else v-model="budget_15" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-full mb-4" v-if="!readOnly">
          <button @click="saveCustomNumbers" class="btn btn--charcoalgray">Lagre</button>
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
  import axios from 'axios';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';
  import Section from '@/views/components/schema/Section.vue';

  export default {
    data() {
      return {
        uploadFinished: false,
        uploadResult: false,
        removeFileResult: false,
        statusMessage: '',
        file: null,
        showConfirm: false,
        numbers: [],
        comments: [],
        inputStartupCosts: this.model && this.model.properties ? this.model.properties.inputStartupCosts : '',
        inputOperationalCosts: this.model && this.model.properties ? this.model.properties.inputOperationalCosts : '',
        inputBudget: this.model && this.model.properties ? this.model.properties.inputBudget : '',
        inputPatientFinancialBasis: this.model && this.model.properties ? this.model.properties.inputPatientFinancialBasis : '',
        startup_1: 0.00,
        startup_2: 0.00,
        startup_3: 0.00,
        startup_4: 0.00,
        startup_5: 0.00,
        startup_6: 0.00,
        operational_1: 0.00,
        operational_2: 0.00,
        operational_3: 0.00,
        operational_4: 0.00,
        operational_5: 0.00,
        operational_6: 0.00,
        operational_7: 0.00,
        operational_8: 0.00,
        operational_9: 0.00,
        operational_10: 0.00,
        operational_11: 0.00,
        operational_12: 0.00,
        operational_13: 0.00,
        operational_14: 0.00,
        operational_15: 0.00,
        operational_16: 0.00,
        operational_17: 0.00,
        operational_18: 0.00,
        operational_19: 0.00,
        operational_20: 0.00,
        operational_21: 0.00,
        operational_22: 0.00,
        operational_23: 0.00,
        operational_24: 0.00,
        operational_25: 0.00,
        budget_1: 0.00,
        budget_2: 0.00,
        budget_3: '',
        budget_4: 0.00,
        budget_5: 0.00,
        budget_6: '',
        budget_7: 0.00,
        budget_8: 0.00,
        budget_9: '',
        budget_10: 0.00,
        budget_11: 0.00,
        budget_12: '',
        budget_13: 0.00,
        budget_14: 0.00,
        budget_15: '',
      };
    },
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      FieldTitle,
      Section,
    },
    mounted() {
      this.getNumbers();
      this.getCustomNumbers();
    },
    computed: {
      fileName() {
        if (this.model.properties && this.model.properties.spreadsheetFiles && this.model.properties.spreadsheetFiles[0]) {
          return this.model.properties.spreadsheetFiles[0];
        }
        if (this.file) {
          return this.file.name;
        }
        return null;
      },
      excelFileUrl() {
        if (this.model.properties && this.model.properties.foundationFileUrl) {
          return this.model.properties.foundationFileUrl;
        }
        return null;
      },
    },
    methods: {
      setDefault(e) {
        if (e.target.value === '') {
          e.target.value = '0';
        }
      },
      removeZero(e) {
        if (e.target.value === '0') {
          e.target.value = '';
        }
      },
      onFileUploadClick() {
        this.$refs.fileInput.click();
      },
      fileUploadChange(e) {
        const fileUploaded = e.target.files || e.dataTransfer.files;
        const [file] = fileUploaded;
        this.file = file;
        this.submitFile();
      },
      submitFile() {
        if (this.file) {
          const formData = new FormData();
          formData.append('file', this.file);

          axios.post(`${window.location.origin}/api/schema/UploadEconomyEvaluation/${this.model.contentId}/økonomiske-aspekter-spesialisthelsetjenesten`, formData)
            .then((response) => {
              this.uploadFinished = true;
              this.uploadResult = response;
              this.getNumbers();
              this.removeFileResult = false;
            }, (error) => {
              this.uploadFinished = true;
              this.uploadResult = false;
              this.statusMessage = error;
            });
        }
      },
      getCustomNumbers() {
        fetch(`${window.location.origin}/api/schema/GetCustomNumbers/${this.model.contentId}`)
          .then((response) => {
            response.json().then((data) => {
              this.startup_1 = data.startup_1;
              this.startup_2 = data.startup_2;
              this.startup_3 = data.startup_3;
              this.startup_4 = data.startup_4;
              this.startup_5 = data.startup_5;
              this.startup_6 = data.startup_6;
              this.operational_1 = data.operational_1;
              this.operational_2 = data.operational_2;
              this.operational_3 = data.operational_3;
              this.operational_4 = data.operational_4;
              this.operational_5 = data.operational_5;
              this.operational_6 = data.operational_6;
              this.operational_7 = data.operational_7;
              this.operational_8 = data.operational_8;
              this.operational_9 = data.operational_9;
              this.operational_10 = data.operational_10;
              this.operational_11 = data.operational_11;
              this.operational_12 = data.operational_12;
              this.operational_13 = data.operational_13;
              this.operational_14 = data.operational_14;
              this.operational_15 = data.operational_15;
              this.operational_16 = data.operational_16;
              this.operational_17 = data.operational_17;
              this.operational_18 = data.operational_18;
              this.operational_19 = data.operational_19;
              this.operational_20 = data.operational_20;
              this.operational_21 = data.operational_21;
              this.operational_22 = data.operational_22;
              this.operational_23 = data.operational_23;
              this.operational_24 = data.operational_24;
              this.operational_25 = data.operational_25;
              this.budget_1 = data.budget_1;
              this.budget_2 = data.budget_2;
              this.budget_3 = data.budget_3;
              this.budget_4 = data.budget_4;
              this.budget_5 = data.budget_5;
              this.budget_6 = data.budget_6;
              this.budget_7 = data.budget_7;
              this.budget_8 = data.budget_8;
              this.budget_9 = data.budget_9;
              this.budget_10 = data.budget_10;
              this.budget_11 = data.budget_11;
              this.budget_12 = data.budget_12;
              this.budget_13 = data.budget_13;
              this.budget_14 = data.budget_14;
              this.budget_15 = data.budget_15;
            });
          });
      },
      saveCustomNumbers() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            startup_1: this.startup_1 !== '' ? this.startup_1 : '0',
            startup_2: this.startup_2 !== '' ? this.startup_2 : '0',
            startup_3: this.startup_3 !== '' ? this.startup_3 : '0',
            startup_4: this.startup_4 !== '' ? this.startup_4 : '0',
            startup_5: this.startup_5 !== '' ? this.startup_5 : '0',
            startup_6: this.startup_6 !== '' ? this.startup_6 : '0',
            operational_1: this.operational_1 !== '' ? this.operational_1 : '0',
            operational_2: this.operational_2 !== '' ? this.operational_2 : '0',
            operational_3: this.operational_3 !== '' ? this.operational_3 : '0',
            operational_4: this.operational_4 !== '' ? this.operational_4 : '0',
            operational_5: this.operational_5 !== '' ? this.operational_5 : '0',
            operational_6: this.operational_6 !== '' ? this.operational_6 : '0',
            operational_7: this.operational_7 !== '' ? this.operational_7 : '0',
            operational_8: this.operational_8 !== '' ? this.operational_8 : '0',
            operational_9: this.operational_9 !== '' ? this.operational_9 : '0',
            operational_10: this.operational_10 !== '' ? this.operational_10 : '0',
            operational_11: this.operational_11 !== '' ? this.operational_11 : '0',
            operational_12: this.operational_12 !== '' ? this.operational_12 : '0',
            operational_13: this.operational_13 !== '' ? this.operational_13 : '0',
            operational_14: this.operational_14 !== '' ? this.operational_14 : '0',
            operational_15: this.operational_15 !== '' ? this.operational_15 : '0',
            operational_16: this.operational_16 !== '' ? this.operational_16 : '0',
            operational_17: this.operational_17 !== '' ? this.operational_17 : '0',
            operational_18: this.operational_18 !== '' ? this.operational_18 : '0',
            operational_19: this.operational_19 !== '' ? this.operational_19 : '0',
            operational_20: this.operational_20 !== '' ? this.operational_20 : '0',
            operational_21: this.operational_21 !== '' ? this.operational_21 : '0',
            operational_22: this.operational_22 !== '' ? this.operational_22 : '0',
            operational_23: this.operational_23 !== '' ? this.operational_23 : '0',
            operational_24: this.operational_24 !== '' ? this.operational_24 : '0',
            operational_25: this.operational_25 !== '' ? this.operational_25 : '0',
            budget_1: this.budget_1 !== '' ? this.budget_1 : '0',
            budget_2: this.budget_2 !== '' ? this.budget_2 : '0',
            budget_3: this.budget_3 !== '' ? this.budget_3 : '',
            budget_4: this.budget_4 !== '' ? this.budget_4 : '0',
            budget_5: this.budget_5 !== '' ? this.budget_5 : '0',
            budget_6: this.budget_6 !== '' ? this.budget_6 : '',
            budget_7: this.budget_7 !== '' ? this.budget_7 : '0',
            budget_8: this.budget_8 !== '' ? this.budget_8 : '0',
            budget_9: this.budget_9 !== '' ? this.budget_9 : '',
            budget_10: this.budget_10 !== '' ? this.budget_10 : '0',
            budget_11: this.budget_11 !== '' ? this.budget_11 : '0',
            budget_12: this.budget_12 !== '' ? this.budget_12 : '',
            budget_13: this.budget_13 !== '' ? this.budget_13 : '0',
            budget_14: this.budget_14 !== '' ? this.budget_14 : '0',
            budget_15: this.budget_15 !== '' ? this.budget_15 : '',
          }),
        };
        fetch(`${window.location.origin}/api/schema/SaveCustomNumbers/${this.model.contentId}`, requestOptions);
      },
      removeFile() {
        axios.post(`${window.location.origin}/api/schema/RemoveEconomyEvaluation/${this.model.contentId}`, null)
          .then((response) => {
            this.file = null;
            this.removeFileResult = response.data;
            this.numbers = null;
          }, (error) => {
            this.statusMessage = error;
          });
      },
      getNumbers() {
        fetch(`${window.location.origin}/api/schema/GetEconomyData/${this.model.contentId}`)
          .then((response) => {
            response.json().then((data) => {
              this.numbers = data.numbers;
              this.comments = data.comments;
              this.inputOperationalCosts = data.textInput && data.textInput.operationalCosts ? data.textInput.operationalCosts : '';
              this.inputBudget = data.textInput && data.textInput.budget ? data.textInput.budget : '';
              this.inputStartupCosts = data.textInput && data.textInput.startupCosts ? data.textInput.startupCosts : '';
              this.inputPatientFinancialBasis = data.textInput && data.textInput.financialBasis ? data.textInput.financialBasis : '';
            });
          });
      },
      save() {
        const textValues = [];
        textValues[0] = this.inputStartupCosts;
        textValues[1] = this.inputOperationalCosts;
        textValues[2] = this.inputBudget;
        textValues[3] = this.inputPatientFinancialBasis;

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid, value: textValues, type: this.type, pageId: this.pageId,
          }),
        };
        this.isSaving = true;
        const self = this;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
            // todo: add 'saved' animation to input
          }).then(() => {
            self.isSaving = false;
          });
      },
    },
    emits: ['onSave'],
  };
</script>
